import React from 'react';
import { createRoot } from 'react-dom/client';
import { RootRouter } from './RootRouter';
import { VisitInfo } from './app/VisitInfo';
import '@fontsource/varela-round';

import LogRocket from 'logrocket';
import { gaEnv, logRocketEnv } from './util/constants';

import ReactGA from 'react-ga4';

if (logRocketEnv.enabled) {
   LogRocket.init(`${logRocketEnv.id}/${logRocketEnv.project}`);
}

if (gaEnv.enabled) {
   ReactGA.initialize(gaEnv.id);
}

VisitInfo();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
   <React.StrictMode>
      <RootRouter />
   </React.StrictMode>
);
