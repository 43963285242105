import { filterTypes } from '../interfaces/sequencer/constants';

export const authStatuses = {
   pending: 'pending',
   loading: 'loading',
   unauthenticated: 'unauthenticated',
   authenticated: 'authenticated',
};

export const permissionsStatuses = {
   pending: 'pending',
   loading: 'loading',
   loaded: 'loaded',
   failed: 'failed',
};

export const highlightTypes = { still: 'still' };

export const assetsUrl = '/content';

export const filterTypeOrder = {
   [filterTypes.lowshelf]: 0,
   [filterTypes.peaking]: 1,
   [filterTypes.highshelf]: 2,
   [filterTypes.lowpass]: 3,
   [filterTypes.bandpass]: 4,
   [filterTypes.highpass]: 5,
   [filterTypes.notch]: 6,
};

export const categories = {
   mixingAndMastering: 'mixing',
   theoryAndComposition: 'composition',
};

export const accessLevels = {
   unauthenticated: 'unauthenticated',
   authenticated: 'authenticated',
   exercise: 'exercise',
   course: 'course',
};

export const keyboardModes = {
   fromName: 'fromName',
};

export const stripeEnv = {
   billingConsoleLink: 'https://billing.stripe.com/p/login/8wM6st2X572c7D26oo',
};

export const logRocketEnv = {
   enabled: window.CONFIG_LOGROCKET_ENABLED,
   id: window.CONFIG_LOGROCKET_ID,
   project: window.CONFIG_LOGROCKET_PROJECT,
};

export const gaEnv = {
   enabled: window.CONFIG_G_ANALYTICS_ENABLED,
   id: window.CONFIG_G_ANALYTICS_ID,
};

export const userPoolEnv = {
   pool: window.CONFIG_USER_POOL,
   client: window.CONFIG_USER_POOL_CLIENT,
};

export const apiEnv = {
   appEndpoint: window.CONFIG_APP_API_ENDPOINT,
};
